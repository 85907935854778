<template>
  <div class="settings-content" :class="{'settings-content__with-main-container' : isDeeplinksPage}">
    <HeadSection
      v-if="!isDeeplinksPage"
      :title="title"
      permissionOfSection="deeplinks"
    />
    <router-view />
  </div>
</template>

<script>
import HeadSection from '@/components/main/headSection'

export default {
  components: {
    HeadSection,
  },
  computed: {
    isDeeplinksPage() {
      return this.$route.path.includes('/settings/deeplinks')
    },
    title() {
      return `Settings | ${this.$route.meta.title}`
    },
  },
}
</script>

<style lang="scss">
.settings-content {
  &__with-main-container {
    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
</style>
